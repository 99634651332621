import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  apiErrorCatcher,
  setAuthTokens,
  setSessionToken,
} from "./common-action-utils";
import {
  forgotPasswordAPI,
  loginAPI,
  passwordChangeAPI,
  resetPasswordFromKeyAPI,
} from "redux/services/newAuth";
import { setUser } from "redux/actions";
import instance from "redux/actions/instance";

const initialState = {
  loading: false,
  formLoading: false,
  error: null,
  formSuccess: false,
  loginData: null,
};

export const forgotPassword = createAsyncThunk(
  "api/forgotPassword",
  async (payload: any, thunkAPI) => {
    // payload contains {"email": "email@mail.com"}

    try {
      const response = await forgotPasswordAPI(payload);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const resetPasswordFromKey = createAsyncThunk(
  "api/resetPasswordFromKey",
  async (payload: any, thunkAPI) => {
    // payload contains {"key": "key", "password":"12321" }

    try {
      const response = await resetPasswordFromKeyAPI(payload);
      return response.data;
    } catch (error: any) {
      const response = error?.response || {};
      const { status, data } = response;
      if (status === 401) {
        // this is stil valid return a success data
        return data;
      }

      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const login = createAsyncThunk(
  "api/login",
  async (payload: any, thunkAPI) => {
    // this support username or email login
    // payload contains {"email": "key", "password":"12321" }
    // payload contains {"username": "key", "password":"12321" }

    try {
      const response = await loginAPI(payload);
      const mainData = response.data;
      const { data, meta } = mainData || {};
      const { user } = data || {};

      await setAuthTokens(meta.access, meta.refresh, meta.session_token);
      // at this point we also need to add the bearer token on header
      //@ts-ignore
      instance.defaults.headers.Authorization = `Bearer ${meta.access}`;
      instance.defaults.headers["X-Session-Token"] = meta.session_token;

      // if there are supervisor_code assign to localstorage
      if (payload.supervisor_code)
        localStorage.setItem("supervisor_code", payload.supervisor_code);
      // NOTE: this is to add the user this can still be optimize for later use.
      await thunkAPI.dispatch(setUser(user));

      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const passwordChange = createAsyncThunk(
  "api/passwordChange",
  async (payload: any, thunkAPI) => {
    // payload contains {"current_password" and "new_password"}
    try {
      const response = await passwordChangeAPI(payload);
      const mainData = response.data;
      const { meta } = mainData || {};

      setSessionToken(meta.session_token);
      instance.defaults.headers["X-Session-Token"] = meta.session_token;

      return response.data;
    } catch (error: any) {
      // apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const newAuthSlice = createSlice({
  name: "newAuthSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = null;
        state.formSuccess = true;
        state.loginData = action.payload;
      })
      .addCase(login.rejected, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = action.payload;
        state.formSuccess = false;
      })

      .addCase(forgotPassword.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(
        forgotPassword.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = null;
          state.formSuccess = true;
        }
      )
      .addCase(forgotPassword.rejected, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = action.payload;
        state.formSuccess = false;
      })

      .addCase(resetPasswordFromKey.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(
        resetPasswordFromKey.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = null;
          state.formSuccess = true;
        }
      )
      .addCase(
        resetPasswordFromKey.rejected,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = action.payload;
          state.formSuccess = false;
        }
      )
      .addCase(passwordChange.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(
        passwordChange.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = null;
          state.formSuccess = true;
        }
      )
      .addCase(passwordChange.rejected, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = action.payload;
        state.formSuccess = false;
      });
  },
});

export default newAuthSlice.reducer;
