import { gql } from "@apollo/client";

// Get applicant with limited data
export const GET_APPLICANT_LIMITED = gql`
  query GetApplicant($id: ID!, $programId: ID!) {
    # Define the variable $id and its type
    applicant(id: $id) {
      # Use the variable $id in the query
      pk
      id
      nationality
      motherNationality
      civilId
      education {
        universityName
      }
      registrations(programId: $programId) {
        edges {
          node {
            id
            pk
            registrationFeesPaid
            status
            paid
          }
        }
      }
    }
  }
`;

export const GET_USER_FOR_APPLICANT = gql`
  query GetUserApplicant($id: ID!, $programId: ID!, $month: String) {
    # Define the variable $id and its type
    user(id: $id) {
      # Use the variable $id in the query
      pk
      id
      applicant {
        pk
        id
        nationality
        motherNationality
        civilId
        education {
          universityName
        }
        registrations(program: $programId, monthChoice: $month) {
          edges {
            node {
              id
              pk
              registrationFeesPaid
              monthChoice {
                pk
                month
              }
              status
              paid
              updatedAt
            }
          }
        }
      }
    }
  }
`;

export const LIST_APPLICANTS_EMAILS = gql`
  query GetApplicantEmail($email: String, $search: String) {
    applicants(search: $search, user_Email_Icontains: $email) {
      edges {
        node {
          pk
          user {
            email
          }
          fullName
          mobileNumber
          parentMobileNumber
        }
      }
    }
  }
`;
