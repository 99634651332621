import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";
import dayjs from "dayjs";
import { DatePickerProps } from "@mui/x-date-pickers";
import { defaultColors } from "./common/Materialui/themes/main/default";
export const customTextButton: SxProps<Theme> = {
  color: defaultColors.dark0,
  fontWeight: 600,
  textTransform: "none",
  justifyContent: "start",
  fontSize: "1rem",
  width: "100%",
};

type ISX = SxProps<Theme>;
export const navbarItemStyle: ISX = {
  color: defaultColors.dark0,
  width: "100%",
  lineHeight: "1.75rem",
  paddingY: "0.7rem",
  fontWeight: 600,
  textTransform: "none",
};

export const defaultTextFieldProps = (
  name: string,
  required = true,
  restProps = { disableDecimals: false }
) => {
  return {
    size: "small",
    name,
    fullWidth: true,
    required,
    InputProps: {
      required,
      min: 0,
      step: "1",
      shrink: true,
    },
    ...(restProps.disableDecimals
      ? {
          onKeyDown: (e: any) => {
            if (e.key === ".") e.preventDefault();
          },
        }
      : {}),
  };
};

export const defaultDatePickerProps = (
  name: string,
  label: string,
  required = true,
  fullWidth = false,
  validateMinDate = true
): DatePickerProps<any> => ({
  format: "YYYY-M-D",
  name,
  slotProps: {
    textField: {
      name,
      label,
      size: "small",
      required,
      fullWidth,
    },
  },
  ...(validateMinDate ? { minDate: dayjs() } : {}),
});
export const defaultTimePickerProps = (
  name: string,
  label: string,
  required = true
) => ({
  slotProps: {
    textField: {
      name,
      label,
      size: "small",
      required,
    },
  },
});
// Add a CSS class for ellipsis
export const ellipsisStyle = {
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitLineClamp: 3, // Maximum number of lines to display
};
export const flexCenter = {
  justifyContent: "center",
  display: "flex",
  alignItems: "center",
};
export const flexJustifyCenter = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};
export const flexCenterCol: SxProps = {
  ...flexCenter,
  flexDirection: "column" as "column" | "row",
};
export const flexJustifyCol = {
  ...flexJustifyCenter,
  flexDirection: "column",
};
